import { graphql } from "gatsby"
import React, { useEffect } from "react"
import Layout from "../components/layout"

export default function KVKK() {
  useEffect(() => {
    function addScrolled() {
      document.getElementById("header").classList.add("scrolled")
    }
    addScrolled()
  })
  return (
    <Layout>
      <div className="full-containerBg">
        <div className="full-container subpage">
          <div className="aydinlatma-metni">
            <div style={{ textAlign: "center" }}>
              <h2>ONUR BOUTIQUE OTEL YALIKAVAK</h2>
              <p>
                <strong>
                  Kişisel Verilerin Korunması Kanunu Uyarınca Hazırlanan
                  Aydınlatma Metni
                </strong>
              </p>
            </div>
            <p>
              ONUR BOUTIQUE OTEL YALIKAVAK olarak kişisel verilerinize saygı
              duyuyoruz. Bu sebeple 6698 sayılı Kişisel Verilerin Korunması
              Kanununun (Bundan sonra KVKK olarak anılacaktır.) getirdiği
              yükümlülükler, sorumluluklar ve haklar ile ilgili sizi
              bilgilendirmek isteriz.
            </p>
            <h3>A) VERİ SORUMLUSU</h3>
            <p>
              Öncelikle ilgili mevzuat kapsamında kişisel birtakım verilerinizi
              kayıt altına almakla yükümlü olduğumuzu ve bu sebeple KVKK
              kapsamında veri sorumlusu olduğumuzu bildirmek isteriz.
            </p>
            <h3>B) İŞLENEN KİŞİSEL VERİLER</h3>
            <p>
              KVKK ve ilgili mevzuata uygun olarak işbu aydınlatma metninde
              belirtilen amaçlar ve hukuki sebepler kapsamında işlenecek kişisel
              veri kategori ve açıklamaları aşağıdaki gibidir:
              <ul>
                <li>
                  Kimlik Bilgileri: Adı soyad, doğum yeri ve tarihi, kimlik
                  numarası
                </li>
                <li>
                  İletişim Bilgileri: Adres, telefon numarası, e-posta adresi,
                </li>
                <li>
                  Finansal Bilgileri: Ödeme yapılan banka hesap bilgileri,
                </li>
              </ul>
            </p>
            <h3>C) KİŞİSEL VERİLERİN HANGİ AMAÇLA İŞLENECEĞİ</h3>
            <p>Kişisel verileriniz:</p>
            <p>
              <ul>
                <li>Konaklama hizmetine ilişkin süreçlerin yürütülmesi,</li>
                <li>
                  ONUR BOUTIQUE OTEL YALIKAVAK’ın satış ve pazarlama
                  politikalarının yürütülebilmesi,
                </li>
                <li>
                  ONUR BOUTIQUE OTEL YALIKAVAK tarafından sunulan ürün ve
                  hizmetlerden sizleri faydalandırmak için gerekli çalışmaların
                  iş birimlerimiz tarafından yapılması,
                </li>
                <li>
                  Müşteri memnuniyetinin ölçülmesi ve artırılmasının sağlanması,
                </li>
                <li>
                  ONUR BOUTIQUE OTEL YALIKAVAK ürün ve hizmetlerine ilişkin
                  varsa şikâyet ve önerilerin toplanması ve değerlendirilmesi,
                </li>
                <li>
                  ONUR BOUTIQUE OTEL YALIKAVAK tarafından sunulan ürün ve
                  hizmetlerin sizlere önerilmesi; ürün ve hizmetlerin satış ve
                  pazarlaması için pazar araştırması faaliyetlerinin planlanması
                  ve icrası,
                </li>
                <li>
                  İş ilişkisi içerisinde olan ilgili kişilerin hukuki, teknik ve
                  ticari iş güvenliğinin temin edilmesi,
                </li>
                <li>Hukuk işlerinin takibi ve yürütülmesi,</li>
                <li>Finans ve muhasebe işlerinin yürütülmesi,</li>
              </ul>
              amaçlarıyla KVKK’nın 5/2 maddesinde belirtilen
            </p>
            <p>
              <ul style={{ listStyleType: "lower-alpha" }}>
                <li> Kanunlarda açıkça öngörülmesi.</li>
                <li>
                  Fiili imkânsızlık nedeniyle rızasını açıklayamayacak durumda
                  bulunan veya rızasına hukuki geçerlilik tanınmayan kişinin
                  kendisinin ya da bir başkasının hayatı veya beden bütünlüğünün
                  korunması için zorunlu olması.
                </li>
                <li>
                  Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya
                  ilgili olması kaydıyla, sözleşmenin taraflarına ait kişisel
                  verilerin işlenmesinin gerekli olması.
                </li>
                <li>
                  Veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi
                  için zorunlu olması.
                </li>
                <li>
                  İlgili kişinin kendisi tarafından alenileştirilmiş olması.
                </li>
                <li>
                  Bir hakkın tesisi, kullanılması veya korunması için veri
                  işlemenin zorunlu olması.
                </li>
                <li>
                  İlgili kişinin temel hak ve özgürlüklerine zarar vermemek
                  kaydıyla, veri sorumlusunun meşru menfaatleri için veri
                  işlenmesinin zorunlu olması.
                </li>
              </ul>
              hukuki sebepleri dahilinde işlenecektir.
            </p>
            <h3>
              D) İŞLENEN KİŞİSEL VERİLERİN KİMLERE VE HANGİ AMAÇLA
              AKTARILABİLECEĞİ
            </h3>
            <p>
              Kişisel verileriniz, aşağıda belirtilen amaçlar ile KVKK’nın 8/2-a
              maddeleri uyarınca ilgili kişinin açık rızası aranmaksızın
              aktarılacaktır:
            </p>
            <p>
              <ul>
                <li>
                  İlgili mevzuattan kaynaklanan yasal yükümlülüklerimizin yerine
                  getirilebilmesi amacıyla Emniyet Genel Müdürlüğü ile
                  paylaşılacaktır.
                </li>
                <li>
                  Ürün ve hizmetlere dair ücretlerin tahsilatı kapsamında finans
                  ve/veya muhasebe işlerinin yürütülmesi amacıyla Gelir İdaresi
                  Başkanlığı ve bankalarla, gerekmesi halinde noter, icra
                  daireleri ve/veya mahkemeler ile paylaşılabilecektir.
                </li>
                <li>
                  İlgili mevzuat uyarınca yasal yükümlülüklerin yerine
                  getirebilmesi amacıyla, gerekmesi veya talep gelmesi halinde,
                  ilgili kamu kurum ve kuruluşları ile paylaşılabilecektir.
                </li>
                <li>
                  Otelde bırakılan ve unutulan eşyaların teslim edilmesi
                  amacıyla anlaşmalı kargo firması ile paylaşılacaktır.
                </li>
                <li>
                  Hukuki süreçlerin takip edilebilmesi amacıyla avukatlarımız
                  ile sır saklama yükümlülüğü çerçevesinde gerektiği kadar
                  paylaşılabilecektir.
                </li>
              </ul>
            </p>
            <h3>E) KİŞİSEL VERİ TOPLAMANIN YÖNTEMİ VE HUKUKİ SEBEBİ</h3>
            <p>
              Kişisel verileriniz, bu metnin “C” maddesinde belirtilen amaçların
              yerine getirilebilmesi için KVKK’nın 5/2 ve 6/2 maddelerinde
              belirtilen hukuki sebeplere dayanarak, otomatik veya otomatik
              olmayan yöntemlerle sözlü, yazılı veya elektronik ortamda elde
              edilmektedir:
              <ul>
                <li>
                  İlgili kişi tarafından form doldurulması, e-posta iletisi
                  gönderilmesi, telefon araması ile iletişime geçilmesi,
                  internet sitesinin ziyaret edilmesi ve sosyal medya
                  platformları üzerinden irtibata geçilmesi.
                </li>
              </ul>
            </p>
            <h3>F) KVKK KAPSAMINDAKİ HAKLARINIZ</h3>
            <p>
              KVKK kapsamında sizlere bazı haklar tanınmış ve bu haklar KVKK’nın
              11. maddesinde şu şekilde belirtilmiştir: Herkes, veri sorumlusuna
              başvurarak kendisiyle ilgili;
              <ul>
                <li>
                  Kişisel veri işlenip işlenmediğini öğrenme, işlenen bilgilere
                  ilişkin veri sorumlusundan bilgi talep etme, hangi amaçla ve
                  amacına uygun kullanılıp kullanılmadığını öğrenmek, kayıt
                  altına alınan verilen yurt içi veya yurt dışında 3. Kişiler
                  ile paylaşılıp paylaşılmadığını, kişisel verinin eksik veya
                  hatalı işlenmesi halinde bunların düzeltilmesini talep etme,
                  kişisel verinin ne kadar süreyle saklanacağını ve kanuni süre
                  sonunda kayıt altına alınmış olan kişisel verinin imhasını
                  talep etme, işlenen veriler sonucunda elektronik ortamda
                  verisi işlenen kişinin aleyhine bir sonucun çıkması durumunda
                  buna itiraz etme, işlenen kişisel verinin hukuka aykırı olarak
                  işlenmesi sebebiyle zarara uğranması halinde zararın
                  giderilmesini talep etme haklarına sahiptir.
                </li>
              </ul>
            </p>
            <h3>G) KİŞİSEL VERİLERİN SAKLANMA SÜRESİ</h3>
            <p>
              ONUR BOUTIQUE OTEL YALIKAVAK, kişisel verilerin işleme amacının
              ortan kalkması, Kanunlar ve ilgili mevzuat kapsamında belirlenen
              zorunlu saklama sürelerinin dolmasıyla bilirlikte kişisel verileri
              siler, yok eder, imha eder veya anonim hale getirir.
            </p>
            <h3>H) DEĞİŞİKLİK VE GÜNCELLEMELER</h3>
            <p>
              İş bu aydınlatma metni, 6698 sayılı Kişisel Verilerin Korunması
              Kanunu ve ilgili mevzuat kapsamında hazırlanmıştır. İlgili
              mevzuatta ve ONUR BOUTIQUE OTEL YALIKAVAK’ın kişisel veri işleme
              amaç ve politikalarında meydana gelecek değişiklikler
              doğrultusunda bahse konu aydınlatma metninde gerekli değişiklikler
              yapılabilir.
            </p>
            <p>
              Saygılarımızla,
              <br />
              ONUR BOUTIQUE OTEL YALIKAVAK
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query KVKK($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    kategoriler: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "galeri_kategori" } } }
      sort: { order: ASC, fields: frontmatter___galeri_sirasi }
    ) {
      edges {
        node {
          frontmatter {
            galeri_sirasi
            title
            title_en
          }
        }
      }
    }
    galeri: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "galeri" } } }
    ) {
      edges {
        node {
          frontmatter {
            kategori
            resim
            baslik
            baslik_en
          }
        }
      }
    }
  }
`
